import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes'

function App() {
	return (
		<main>
			<RouterProvider
				router={router}
				// fallbackElement={<BigSpinner />}
			/>
		</main>
	)
}

export default App
