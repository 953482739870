import React from 'react'
import {Card, CardBody, CardHeader} from '@nextui-org/react'
import {MoodleLoginButton, YandexLoginWidget} from "@ITReshalo/itreshalofl";
import {AUTH_SUBDOMAIN, YANDEX_CLIENT_ID} from "../../constants";
import api from "../../api";

const LoginPage = () => {
    return (
        <Card>
            <CardHeader>
                <p className='text-xl font-semibold'>Откуда скачать пароли?</p>

            </CardHeader>
            <CardBody className='gap-2 p-2'>
                {/*<MoodleButton />*/}

                <MoodleLoginButton/>

                <div className='relative p-4 w-full text-content2-foreground'>
                    <span
                        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-content1 p-2 '>или</span>
                    <hr className='border-text-content2-foreground'/>
                </div>

                <YandexLoginWidget client_id={YANDEX_CLIENT_ID} subdomain={AUTH_SUBDOMAIN}
                                   sendRequest={data => api.apiClient.sendRequest(data)}/>
                {/*<VkLoginWidget />*/}
                {/*<VkLoginNew />*/}
            </CardBody>
        </Card>
    )
}

export default LoginPage
