import {RouteObject} from 'react-router-dom'
import LoginPage from '../pages/login/loginPage'
import ContinuePage from '../pages/continue/continuePage'
import AvailableManager from '../components/AvailableManager/availableManager'

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <LoginPage/>,
    },
    // {
    //     path: '/continue',
    //     element: (
    //         <AvailableManager>
    //             <ContinuePage/>
    //         </AvailableManager>
    //     ),
    // },

]
