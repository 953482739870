import {
	ApiClientFactory,
	getApiProxy
} from '@ITReshalo/itreshalofl-api'

let domain = '1112295-cc94919.test.ru'
let security = false

if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
	domain = 'it-reshalo.ru'
	security = true
}

const apiClientFactory = new ApiClientFactory(domain, security)
const nonAuthApiClient = apiClientFactory.createClient()

const proxy = getApiProxy(nonAuthApiClient)

const exp = {
	apiClient: nonAuthApiClient,
	proxy
}

export default exp
